var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5 w-85 big-div",staticStyle:{"z-index":"100"},style:(_vm.getPageDesign())},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0 h-100",class:{
      'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
      'image-division': _vm.isDesktopView,
    }},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage'),_vm._v(" :style=\" getUniversalImageStyle( isDesktopView, pageDesign.imageBlock.style ) + getImageOpacity(pageDesign.imageBlock.style) \" :class=\"[ `pic-SharePage`, pageDesign.imageBlock.src == '/images/default_start_page.png' ? 'default-bg' : '', ]\" :src=\"MainImgSrc(pageDesign.imageBlock.src)\" alt ")],1)])]),_c('div',{staticClass:"",class:!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout,style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles) +
      _vm.getScrollCSS(
        _vm.pageDesign.pageStyles,
        _vm.isDesktopView,
        _vm.pageDesign.imageBlock.style
      ))},[_c('div',_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
              _vm.getPaddingCss(pro.style) +
              _vm.getMarginCss(pro.style) +
              _vm.getColorWithOpacity(pro.style) +
              _vm.getTextRotate(pro.style) +
              _vm.getLineHeight(pro.style) +
              _vm.getTextAlign(pro.style) +
              _vm.getFontStyle(pro.style,_vm.isDesktopView)),domProps:{"innerHTML":_vm._s(pro.content)}}):_c('div',{style:('display:flex;justify-content:' + pro.style.position + ';')},[_c('button',{style:(_vm.getButtonCss(pro.style,_vm.isDesktopView))},[_c('span',{style:({
                  opacity: `${pro.style.textOpacity}%`,
                  fontSize: `${pro.style.fontSize}px`,
                })},[_vm._v(" "+_vm._s(pro.text)+" ")]),_vm._v(" "+_vm._s(pro.style.alignItems)+" ")])])])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0 h-100",class:{
      'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
      'image-division': _vm.isDesktopView,
    }},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{class:[
            `pic-SharePage`,
            _vm.pageDesign.imageBlock.src == '/images/default_start_page.png'
              ? 'default-bg'
              : '',
          ],style:(_vm.getUniversalImageStyle(
              _vm.isDesktopView,
              _vm.pageDesign.imageBlock.style
            ) +
            _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
            _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.pageDesign.imageBlock.src),"alt":""}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }